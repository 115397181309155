@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Permanent Marker';
    src: url('../public/fonts/PermanentMarker-Regular.ttf');
}

html {
    @apply text-content;
}

/* @font-face {
    font-family: "Roboto";
    src: url("../public/fonts/Roboto-Regular.ttf");
} */

h1 {
    @apply text-4xl mb-4;
}

h2 {
    @apply text-3xl mb-2;
}

h3 {
    @apply text-2xl mb-2;
}

h4 {
    @apply text-xl mb-2;
}

h5 {
    @apply text-lg mb-2;
}

h6 {
    @apply text-base mb-2;
}

p {
    @apply mb-4;
}

blockquote {
    @apply border-l-4 border-gray-200 italic my-8 pl-8;
}

.disabled-field {
    background-color: #d6dce0; /* Replace #yourColorHere with the exact color code you're using */
    opacity: 1; /* Ensure full opacity unless you want it slightly transparent */
    /* Add any other styles to ensure uniform appearance */
}

/* img {
    @apply my-8 object-cover w-full
} */

.container-tooltip:hover .tooltip {
    visibility:visible;
}